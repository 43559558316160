import { cn } from "@/src/lib/utils.ts";
import Spinner from "@/src/components/ui/atoms/Spinner.tsx";

export default function LoadingIndicatorFull(props: { className?: string }) {
  return (
    <span className={cn("inline-flex items-center space-x-2", props.className)}>
      <span className="font-light">Loading...</span>
      <Spinner className="size-8" />
    </span>
  );
}
