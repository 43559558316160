import React, {
  forwardRef,
  ReactNode,
  ReactElement,
  useEffect,
  useRef,
  useState,
  useId,
} from "react";
import { Props } from "react-select";
import { useClickAway } from "@uidotdev/usehooks";
import { Button } from "@/src/components/ui/button.tsx";
import { cn } from "@/src/lib/utils.ts";
import dynamic from "next/dynamic";
import LoadingIndicatorFull from "@/src/components/layout/LoadingIndicatorFull.tsx";

const Select = dynamic(() => import("react-select"), {
  loading: () => <LoadingIndicatorFull />,
});

interface SelectTextProps {
  fixedContextMenuWidth?: number;

  textPlaceholder?: (render: any) => ReactElement;
  options: { value: any; label: string }[];
  value?: any;
  onChange?: (data: any) => void;
}

export default forwardRef(
  (
    {
      fixedContextMenuWidth = 200,
      value,
      onChange,
      options = [],
      defaultValue,
      className,
      menuPlacement,
      textPlaceholder = (r) => r,
      ...rest
    }: Omit<Props, "options" | "placeholder"> &
      SelectTextProps &
      Omit<React.HTMLAttributes<any>, "children">,
    ref,
  ) => {
    const id = useId();
    const [menuOpen, setMenuOpen] = useState(false);
    const [innerValue, setInnerValue] = useState(value);
    useEffect(() => setInnerValue(value), [value]);

    const clickOutsideRef = useClickAway<HTMLDivElement>((e) => {
      if (!(e.target as any).closest("button")) {
        setMenuOpen(false);
      }
    });

    const Control = ({ menuIsOpen }: any) => <></>;

    const innerRefValue: any = options.find(
      (option) => option?.value == innerValue?.value,
    );

    return (
      <span className="relative">
        <span>
          {textPlaceholder
            ? textPlaceholder(
                <Button
                  onClick={() => setMenuOpen((p) => !p)}
                  type="button"
                  variant="link"
                  className="!h-auto !p-0"
                >
                  {innerRefValue?.label || "none"}
                </Button>,
              )
            : "no placeholder"}
        </span>

        <div
          className={cn({
            "absolute top-0 z-50": menuPlacement == "top",
            "absolute bottom-0 z-50": !menuPlacement,
          })}
          ref={clickOutsideRef}
        >
          <div
            className={cn("bg-white text-black", { "d-none": !menuOpen })}
            style={{ width: fixedContextMenuWidth }}
          >
            {menuOpen && (
              <Select
                menuPosition="absolute"
                instanceId={id}
                menuPlacement={menuPlacement}
                components={{ Control }}
                menuIsOpen={menuOpen}
                value={value}
                onChange={(data) => {
                  setInnerValue(data);
                  if (onChange) onChange(data);
                }}
                ref={ref as any}
                defaultValue={defaultValue}
                options={options}
                closeMenuOnSelect={true}
                onMenuClose={() => setMenuOpen(false)}
                {...rest}
              />
            )}
          </div>
        </div>
      </span>
    );
  },
);
