import { ReactElement } from 'react';
import {
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Tooltip,
} from '@/src/components/ui/tooltip';
import { HelpCircle } from 'lucide-react';
import { cn } from '@/src/lib/utils.ts';

type CommonProps = {
  iconClass?: string;
};

type PropDefined = { text: string } & CommonProps;
type ChildDefined = { children: ReactElement } & CommonProps;

export type ExplainationIconProps = PropDefined | ChildDefined;
export function ExplainationIcon(props: ExplainationIconProps) {
  const { iconClass } = props;
  const children = 'children' in props && props.children;
  const text = 'text' in props && props.text;

  const renderContent = children || text;

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <HelpCircle className={cn('size-4', iconClass)} />
        </TooltipTrigger>
        <TooltipContent className="break-word max-w-[300px] text-center">
          {renderContent}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
