import { TFunction } from '@/src/components/layout/ProvideLanguageContext.tsx';

export function getCountryCodeEmoji(country: string) {
  return {
    DE: '🇩🇪',
    DEU: '🇩🇪',
    AT: '🇦🇹',
    AUT: '🇦🇹',
    CH: '🇨🇭',
    CHE: '🇨🇭',
  }[country];
}

export function getCountryCodeTranslation(
  countryCode3: string,
  translator: TFunction,
) {
  return translator(`STATIC.ISO3166_MAP.${countryCode3}` as any);
}
