import {
  ExplainationIcon,
  ExplainationIconProps,
} from '@/src/components/ui/molecules/explaination-icon.tsx';
import { cn } from '@/src/lib/utils.ts';
import { cva, VariantProps } from 'class-variance-authority';

const statCardVariants = cva(
  'bg-card text-card-foreground flex cursor-default flex-row items-center justify-between rounded-md',
  {
    variants: {
      variant: {
        default: '',
      },
      size: {
        default: 'p-3',
        lg: 'p-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

type StatCardProps = {
  className?: string;
  stat: {
    label: string;
    sublabel?: string | number | null;
    value: string | number;
    subline?: string;
    explaination?: ExplainationIconProps;
  };
} & VariantProps<typeof statCardVariants>;

export function StatCard({
  className,
  variant,
  size,
  stat: { label, subline, sublabel, value, explaination },
}: StatCardProps) {
  return (
    <div className={cn(statCardVariants({ size, variant }), className)}>
      <div className="flex flex-col">
        <div className="text-muted-foreground">{label}</div>
        {subline && (
          <div className="text-muted-foreground/50 text-xs uppercase">
            {subline}
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2">
        <div className="flex flex-col">
          <div className="text-xl font-semibold">{value}</div>
          {sublabel && (
            <div className="text-muted-foreground/50 text-sm uppercase">
              {sublabel}
            </div>
          )}
        </div>
        {explaination && <ExplainationIcon {...explaination} />}
      </div>
    </div>
  );
}
