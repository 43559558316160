import { useLocale } from 'next-intl';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';
import { escapeRegExp, thru } from 'lodash';

export default function useGetAvailableLanguages() {
  const currentLocale = useLocale();

  const { useRouter, usePathname, pathnames } = useLanguageContext();
  let pathname = usePathname();
  const router = useRouter();

  if (pathname !== '/' && pathname.slice(-1) != '/') pathname = pathname + '/';

  const findPathDeep = (findPath: string) => {
    return thru(
      Object.entries(pathnames).find(([k, v]) => {
        const exists = Object.values(v).find((path) => {
          const matchExact = path == findPath;

          const matchByPlaceholder = findPath.match(
            new RegExp(
              `^${path.replaceAll(new RegExp('\\([^)]+\\)', 'ig'), '[a-z0-9-]+')}$`,
            ),
          );

          return matchExact || matchByPlaceholder;
        });

        if (exists) {
          return v;
        }
      }),
      (e) => e && e[1],
    );
  };

  const existingTranslations = Object.entries(findPathDeep(pathname) || {})
    .filter(([k, v]) => !!v)
    .map(([k, v]) => k);

  return [
    { label: 'Deutsch', value: 'de' },
    { label: 'English', value: 'en' },
  ].filter((lang) => {
    return (
      lang.value === 'de' || // 'de' nie filtern
      existingTranslations.includes(lang.value)
    );
  });
}
