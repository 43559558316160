'use client';
import { ImageLoader } from 'next/image';
import type { ScraperMedia } from '@/src/graphql/__generated__/graphql.ts';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { last } from 'lodash';
import { cn } from '@/src/lib/utils.ts';
import { ReactEventHandler, useMemo, useState } from 'react';
import { ImageProps, Image } from '@/src/components/ui/atoms/image';
import { ScraperMediaLoadableFragment } from '@/src/graphql/__generated__/apollo.tsx';

const scraperMediaLoader: (media: Partial<ScraperMedia>) => ImageLoader = (
  media,
) => {
  return (data) => {
    if (media.srcset) {
      const smallestFit = Object.entries(media.srcset).find(
        ([size, _]) => Number(size) >= data.width,
      );

      return (
        (smallestFit && smallestFit[1]) || last(Object.values(media.srcset))
      );
    } else {
      return data.src + `&ow=${data.width}`;
    }
  };
};

export type OptimoizedScraperMediaProps = Omit<ImageProps, 'src'> & {
  media:
    | Pick<ScraperMedia, 'placeholder' | 'srcset' | 'url'>
    | ScraperMediaLoadableFragment
    | null
    | undefined;
};

export default function OptimizedScraperMedia({
  media,
  ...props
}: OptimoizedScraperMediaProps) {
  // When no srcset & url immedeitaly fallback to error
  const [hasError, setError] = useState<boolean>(false);

  if (!media || hasError) {
    //
    // Use base64 as fallback
    //
    if (media?.placeholder?.base64) {
      return (
        <Image
          src={media?.placeholder?.base64}
          width={0}
          height={0}
          {...props}
          className={cn(props.className, 'bg-[#d0d0d0] object-fill')}
        />
      );
    }
    //
    // Use Placeholder image
    //
    return (
      <Image
        src={'/images/placeholder.svg'}
        width={0}
        height={0}
        {...props}
        className={cn(props.className, 'bg-[#d0d0d0] object-contain')}
      />
    );
  }

  const additional: Optional<ImageProps> = {};
  if (media.placeholder && media.placeholder.base64) {
    additional.placeholder = 'blur';
    additional.blurDataURL = media.placeholder.base64;
  }

  const onError: ReactEventHandler<HTMLImageElement> = () => {
    setError(true);
  };

  return (
    <Image
      src={media.url || 'placeholder'}
      loader={scraperMediaLoader(media)}
      onError={onError}
      width={0}
      height={0}
      {...additional}
      {...props}
    />
  );
}
