import React, {
  Children,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cn } from '@/src/lib/utils.ts';
import { ArrowBigRight } from 'lucide-react';
import { ScrollBar, ScrollArea } from '@/src/components/ui/scroll-area.tsx';
import { debounce } from 'lodash';

type DotsProps = {
  amount: number;
  active: [number, number];
};
function Dots({ amount, active }: DotsProps) {
  const [lb, ub] = active;
  return (
    <div className="flex w-full flex-row justify-center">
      <div className="bg-muted-foreground/5 mt-2 flex h-6 flex-row items-center justify-center gap-1 rounded-xl px-5">
        {Array.from({ length: amount }).map((_, i) => {
          const isActive = i >= lb && i < ub + 1;
          return (
            <span
              key={i}
              className={cn(
                'bg-border/40 size-3 rounded-full transition-all duration-500',
                {
                  'bg-border': isActive,
                },
              )}
            />
          );
        })}
      </div>
    </div>
  );
}

type MediaHorizontalGalleryProps = PropsWithChildren;
export function MediaHorizontalGallery({
  children,
}: MediaHorizontalGalleryProps) {
  const childCount = useMemo(() => React.Children.count(children), [children]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [hasMoved, setHasMoved] = useState<boolean>(false);
  const [activeItems, setActiveItems] = useState<[number, number]>();

  useEffect(() => {
    if (!scrollContainerRef.current) return;
    const scrollListener = debounce(
      () => {
        const items = scrollContainerRef.current?.querySelectorAll(
          '.item-container > *',
        );
        const containerRect =
          scrollContainerRef.current?.getBoundingClientRect();
        const scrollLeft = scrollContainerRef.current?.scrollLeft;
        if (
          items?.length &&
          scrollLeft !== undefined &&
          containerRect !== undefined
        ) {
          let firstElementIndex: number | null = null;
          let lastElementIndex: number | null = null;
          Array.from(items).forEach((e, index) => {
            const rect = e.getBoundingClientRect();
            if (
              firstElementIndex === null &&
              rect.x >= containerRect.x - (containerRect.width * 3) / 4 &&
              rect.x + rect.width > containerRect.x
            ) {
              firstElementIndex = index;
            }

            if (rect.x < containerRect.x + containerRect.width) {
              lastElementIndex = index;
            }
          });

          if (firstElementIndex !== null && lastElementIndex !== null) {
            setActiveItems([firstElementIndex, lastElementIndex]);
          }
        }
      },
      10,
      { maxWait: 50 },
    );

    scrollContainerRef.current.addEventListener('scroll', scrollListener);
    scrollListener();

    return () =>
      scrollListener &&
      scrollContainerRef.current?.removeEventListener('scroll', scrollListener);
  }, [scrollContainerRef]);

  /**
   * Initialen Scroll falls erstes Element kleiner als Container
   */
  useEffect(() => {
    if (scrollContainerRef.current) {
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
      const firstChildRect = scrollContainerRef.current
        .querySelector('.item-container > *:first-child')
        ?.getBoundingClientRect();
      if (!firstChildRect) return;
      if (firstChildRect.width + 30 > containerRect.width) {
        scrollContainerRef.current.scrollLeft =
          Math.abs(firstChildRect.width - containerRect.width) + 30;
      }
    }
  }, [scrollContainerRef]);

  return (
    <div className="relative flex flex-row">
      <ScrollArea
        type="auto"
        ref={scrollContainerRef}
        onScrollCapture={() => {
          if (
            scrollContainerRef.current?.scrollLeft &&
            scrollContainerRef.current.scrollLeft > 100
          ) {
            setHasMoved(true);
          }
        }}
        className="w-full pt-5"
      >
        <div className="item-container flex flex-row gap-4">{children}</div>
        <ScrollBar className="top-0" orientation="horizontal" />
      </ScrollArea>
      <div
        className={cn(
          'pointer-events-none absolute right-4 top-[calc(50%-3rem)] z-10 min-h-full animate-pulse lg:animate-none',
          { hidden: hasMoved || childCount <= 1 },
        )}
      >
        <div className="bg-muted/70 rounded-full p-4">
          <ArrowBigRight className="text-muted-foreground size-12" />
        </div>
      </div>
    </div>
  );
}
