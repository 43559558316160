import { City } from '@/src/graphql/__generated__/graphql.ts';
import { useMemo } from 'react';
import {
  getCountryCodeEmoji,
  getCountryCodeTranslation,
} from '@/src/helpers/country.ts';
import { useTranslations } from '@/src/hooks/useTranslations';

type Props = {
  city: Pick<City, 'name'> | null | undefined;
  country?: string | null;
};
export function CityCountryLine({ city, country }: Props) {
  const trans = useTranslations();

  const cityName = city?.name;

  const [countryEmoji, countryName] = useMemo(
    () =>
      country
        ? [
            getCountryCodeEmoji(country),
            getCountryCodeTranslation(country, trans),
          ]
        : [],
    [country],
  );

  if (!cityName && !countryName) return <></>;

  return (
    <span className="inline-flex flex-wrap gap-x-3">
      {country && (
        <span className="text-muted-foreground">
          {countryEmoji} {countryName}
          {/*{name ? ',' : ''}*/}
        </span>
      )}
      {cityName && <span>{cityName}</span>}
    </span>
  );
}
