import { ComponentPropsWithoutRef } from 'react';
import dynamic from 'next/dynamic';

type InstagramLogoProps = Pick<
  ComponentPropsWithoutRef<
    (typeof import('@/public/images/social-media-icons/instagram_colorful.svg'))['ReactComponent']
  >,
  'className'
> & { colorful?: boolean };

const InstagramColorfulLogo = dynamic(
  () =>
    import('@/public/images/social-media-icons/instagram_colorful.svg').then(
      (e) => e.ReactComponent,
    ),
  { ssr: false },
);

const InstagramBwLogo = dynamic(
  () =>
    import('@/public/images/social-media-icons/instagram.svg').then(
      (e) => e.ReactComponent,
    ),
  { ssr: false },
);

export function InstagramLogo({ colorful, ...props }: InstagramLogoProps) {
  if (colorful) {
    return <InstagramColorfulLogo {...props} />;
  }
  return <InstagramBwLogo {...props} />;
}
