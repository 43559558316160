import React, { PropsWithChildren, useState } from 'react';
import { ErrorBoundary } from '@sentry/nextjs';
import { cn } from '@/src/lib/utils.ts';
import { useTranslations } from '@/src/hooks/useTranslations';
import { Button } from '@/src/components/ui/button.tsx';
import Spinner from '@/src/components/ui/atoms/Spinner.tsx';
import { DisplayError } from '@/src/components/layout/error-boundary/display-error.ts';

type Props = PropsWithChildren<{ className?: string }>;
export function FullsizeErrorBoundary({ children, className }: Props) {
  const trans = useTranslations();

  const [isRetrying, shouldRetry] = useState<boolean>(false);

  return (
    <ErrorBoundary
      fallback={({ error, resetError }) => {
        const message =
          error instanceof DisplayError
            ? error.displayMessage
            : trans('COMMON.ERROR');

        return (
          <div
            className={cn(
              'flex size-full flex-row items-center justify-center',
              className,
            )}
          >
            <div className="flex flex-col items-center gap-4">
              {message}
              <Button
                variant="outline"
                size="lg"
                className="bg-secondary/40"
                disabled={isRetrying}
                onClick={() => {
                  shouldRetry(true);
                  resetError();
                }}
              >
                {trans('COMMON.RETRY')}
                {isRetrying && <Spinner className="ml-4 size-4" />}
              </Button>
            </div>
          </div>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
