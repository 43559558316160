import TextSelect from '@/src/components/layout/TextSelect.tsx';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';
import { useLocale } from 'next-intl';
import useGetAvailableLanguages from '@/src/hooks/useGetAvailableLanguages.ts';

export default function LanguageSwitcherSelect() {
  const currentLocale = useLocale();

  const { useRouter, usePathname } = useLanguageContext();
  const pathname = usePathname();
  const router = useRouter();

  const availableLanguages = useGetAvailableLanguages();

  return (
    <TextSelect
      value={{ value: currentLocale }}
      onChange={(value) => {
        router.replace(pathname, { locale: value.value });
      }}
      textPlaceholder={(render) => <>{render}</>}
      options={availableLanguages}
    />
  );
}
