import { default as NextImage, ImageProps as NextImageProps } from 'next/image';
import { useMemo } from 'react';

type ResolvableSize = string | number;
export type ImageProps = Omit<NextImageProps, 'sizes'> & {
  sizes?:
    | NextImageProps['sizes']
    | [ResolvableSize]
    | [
        ResolvableSize,
        {
          lg?: ResolvableSize;
        },
      ];
};

export function Image({ sizes, ...props }: ImageProps) {
  const parsedSizes = useMemo(() => {
    if (typeof sizes === 'string' || !Array.isArray(sizes)) {
      return sizes;
    }

    const resolveSize = (
      size: ResolvableSize | undefined,
    ): string | undefined => {
      return typeof size === 'number' ? `${size}px` : size;
    };
    if (sizes.length === 1) {
      return resolveSize(sizes[0]);
    }

    if (sizes.length === 2) {
      const { lg } = sizes[1];
      const lgResolved = resolveSize(lg);

      const medias = [
        lgResolved ? `(min-width: 1024px) ${lgResolved}` : undefined,
      ].filter((e) => !!e);

      if (medias.length > 0) {
        return `${medias.join(',')}, ${resolveSize(sizes[0])}`;
      }
      return resolveSize(sizes[0]);
    }
  }, [sizes]);

  return <NextImage {...props} sizes={parsedSizes} />;
}
