"use client";

import Image from "next/image";
import { Mail, MapPin } from "lucide-react";
import { useTranslations } from "@/src/hooks/useTranslations.tsx";
import ClientLink from "@/src/components/layout/ClientLink.tsx";

import logo from "@/public/images/logo-only.svg";
import LanguageSwitcherSelect from "@/src/components/layout/LanguageSwitcherSelect.tsx";
import { JournalAttributes, Data } from "@/src/lib/strapi/strapi-model.ts";

export default function Footer({
  journalSuggestions,
}: {
  journalSuggestions: Data<JournalAttributes>[];
}) {
  const trans = useTranslations();

  return (
    <footer className="dark flex flex-1 flex-col bg-black">
      <div className="relative z-10 overflow-hidden bg-gradient-to-t from-[#111213] to-black pt-10 text-foreground md:pt-36">
        <div className="container grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3 lg:gap-0">
          <div className="flex flex-col items-center">
            <Image
              src={logo}
              width={200}
              height={200}
              className="size-[200px]"
              alt="logo-only"
            />

            <p className="mt-10 text-2xl">{trans("FOOTER.GOT_QUESTIONS")}</p>
            <p className="mt-2 text-foreground/50">
              {trans("FOOTER.GOT_QUESTIONS_SUB")}
            </p>

            <div className="mt-6 grid grid-cols-3 gap-5">
              <span className="place-self-center">
                <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-foreground/30">
                  <Mail className="text-foreground/80" />
                </div>
              </span>
              <div className="col-span-2 flex flex-col">
                <span className="text-foreground/50">
                  {trans("COMMON.EMAIL")}
                </span>
                <span>info@influace.de</span>
              </div>
              <span className="place-self-center">
                <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-foreground/30">
                  <MapPin className="text-foreground/80" />
                </div>
              </span>
              <div className="col-span-2 flex flex-col">
                <span className="text-foreground/50">
                  {trans("COMMON.LOCATION")}
                </span>
                <span>München</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-8">
            <h3 className="font-bold">{trans("FOOTER.PRODUCTS")}</h3>
            <ClientLink className="text-foreground/50" href="/discovery">
              {trans("NAVIGATION.SEARCH_INFLUENCER")}
            </ClientLink>
          </div>
          <div className="flex flex-col items-center gap-8">
            <h3 className="font-bold">
              {trans("FOOTER.NEWEST_JOURNAL_POSTS")}
            </h3>
            {journalSuggestions.map((journal) => (
              <ClientLink
                key={journal.id}
                className="text-foreground/50"
                onPageActive="HIDE"
                href={`${journal.attributes.url.data.attributes.slug_de}`}
              >
                {journal.attributes.title}
              </ClientLink>
            ))}
          </div>
        </div>

        <div className="container pb-36">
          <hr className="mb-5 mt-14" />

          <div className="flex flex-col items-center justify-between gap-5 md:flex-row">
            <span>© 2023, InfluACE.</span>
            <div className="flex flex-row">
              <span className="mr-7 text-foreground/50">
                <span className="mr-2">{trans("FOOTER.LANGUAGE")}:</span>
                <LanguageSwitcherSelect />
              </span>
              <div className="content space-x-3">
                <ClientLink href="/impressum">
                  {trans("FOOTER.IMPRINT")}
                </ClientLink>
                <ClientLink href="/datenschutz">
                  {trans("FOOTER.DATA_PRIVACY")}
                </ClientLink>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 -z-10 flex max-w-full justify-center object-center lg:w-full lg:max-w-none">
          <Image
            className=" h-auto w-full min-w-[2000px] lg:min-w-[1400px]"
            src="/images/footer.png"
            width={1900}
            height={418}
            alt="footer"
          />
        </div>
      </div>
    </footer>
  );
}
